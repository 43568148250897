import React from "react"
import { useMutation } from "react-query"
import { webApiHandler } from "../utils"

export const useSendOtpMutation = () => {
  return useMutation(payload => webApiHandler.sendOtp(payload))
}

export const useLoginMutation = () => {
  return useMutation(payload => webApiHandler.verifyOtp(payload))
}

export const useCreateUserMutation = () => {
  return useMutation(payload => webApiHandler.createUser(payload))
}

export const useEditUserMutation = () => {
  return useMutation(({ id, ...payload }) =>
    webApiHandler.editUser(id, payload)
  )
}

export const useReplyFeedbackMutation = () => {
  return useMutation(({ id, ...payload }) =>
    webApiHandler.feedbackReply(id, payload)
  )
}
