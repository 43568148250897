import React, { useState, useEffect, useCallback } from "react"
import { Navigate } from "react-router-dom"
import { getUserEnum } from "../utils"

function PrivateRoute({ element }) {
  const [sessionToken, setToken] = useState(localStorage.getItem("token"))
  const [user, setUser] = useState(getUserEnum())

  const storageListener = useCallback(() => {
    setToken(localStorage.getItem("token"))
    setUser(getUserEnum())
  }, [])

  useEffect(() => {
    window.addEventListener("storage", storageListener)
    return () => {
      window.removeEventListener("storage", storageListener)
    }
  }, [])

  if (!user || !sessionToken) return <Navigate to="/" replace />
  return element
}

export default PrivateRoute
