import React, { useState } from "react"
import { DateDropdown, Dropdown } from "../../../../../atoms"
import BarChart from "../../../../../charts/bar-chart"
import { data } from "../../../../../charts/sample-data/bar-chart"

function AccessAreaChart(props) {
  const [usedData, setUsedData] = useState(
    data.map(e => ({ ...e, bars: [{ ...e.bars[0], color: "#F2994A" }] }))
  )

  const [selectedArea, setSelectedArea] = useState({ name: "Access Area 1" })

  return (
    <>
      <div className="d-flex align-items-center p-4">
        <div className="chart-description w-50">
          <Dropdown
            className="border-0 no-hover-highlight"
            selectedOption={selectedArea}
            onChange={setSelectedArea}
            menu={[
              { name: "Access Area 1" },
              { name: "Access Area 2" },
              { name: "Access Area 3" }
            ]}
          />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi
            suscipit mollitia officia corrupti.
          </p>
        </div>
        <DateDropdown className="ms-auto" />
      </div>
      <div className="text-center position-relative">
        <BarChart height={500} data={usedData} id="owner-access-area-chart" />
      </div>
    </>
  )
}

export default AccessAreaChart
