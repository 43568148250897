import React, { useEffect, useLayoutEffect, useState } from "react"
import * as d3 from "d3"

function BarChart({
  id,
  width = 550,
  height = 324,
  paddings = { top: 25, left: 25, right: 25, bottom: 50 },
  startYscaleFrom = 0,
  data = [],
  barWidth = 14
}) {
  const createScales = () => {
    const _xScale = d3
      .scaleBand()
      .domain(data.map(e => e.bandName))
      .range([paddings.left, width - paddings.right])
    _xScale.idd = Date.now()

    const _yScale = d3
      .scaleLinear()
      .domain([
        startYscaleFrom,
        d3.max(data.map(e => e.bars.map(bar => bar.height)).flatMap(e => e))
      ])
      .range([height - paddings.bottom, paddings.top])
    _yScale.idd = Date.now()
    return [_xScale, _yScale]
  }

  const [scaleX, setScalX] = useState(undefined)
  const [scaleY, setScalY] = useState(undefined)

  const createAxes = () => {
    if (scaleX && scaleY) return [d3.axisBottom(scaleX), d3.axisLeft(scaleY)]
    return []
  }
  const barMargin = (bandwidth, count) =>
    (bandwidth - count * barWidth) / (count + 1)

  const getBarEnterFn = enter => {
    enter
      .append("rect")
      .attr("width", barWidth)
      .attr("fill", d => d.color)

      .attr("class", d => "entered " + d.bandName)
      .attr("transform", (d, i) => {
        const rightShift =
          scaleX(d.bandName) +
          barMargin(scaleX.bandwidth(), d.count) * (i + 1) +
          barWidth * (i + 1)
        const downshift = height - paddings.bottom
        return `translate(${rightShift},${downshift}) rotate(180)`
      })
      .transition()
      .duration(1000)
      .attr("height", d => d.height)
      .attr("rx", barWidth / 2)

    enter
      .append("rect")
      .attr("width", barWidth)
      .attr("fill", d => d.color)
      .attr("height", d => (d.height < barWidth / 2 ? d.height : barWidth / 2))
      .attr("transform", (d, i) => {
        const rightShift =
          scaleX(d.bandName) +
          barMargin(scaleX.bandwidth(), d.count) * (i + 1) +
          barWidth * (i + 1)
        const downshift = height - paddings.bottom
        return `translate(${rightShift},${downshift}) rotate(180)`
      })
  }

  const getBarGroupEnterFunction = enter => {
    return enter
      .append("g")
      .attr("class", "bars")
      .selectAll("rect")
      .data(d =>
        d.bars.map(e => ({ ...e, bandName: d.bandName, count: d.bars.length }))
      )
      .join(getBarEnterFn)
  }

  const getBarGroupUpdateFunction = update => {
    update.selectAll("rect").remove()
    update
      .selectAll("rect")
      .data(d =>
        d.bars.map(e => ({ ...e, bandName: d.bandName, count: d.bars.length }))
      )
      .join(getBarEnterFn)
  }

  useEffect(() => {
    const [x, y] = createScales()
    setScalX(() => x)
    setScalY(() => y)
  }, [data])

  useEffect(() => {
    const [xAxisCall, yAxisCall] = createAxes()
    if (xAxisCall && yAxisCall) {
      const svgContainer = d3.select(`svg#${id}`)
      const svg = svgContainer.select("g.container").node()
        ? svgContainer.select("g.container")
        : svgContainer.append("g").attr("class", "container")

      const xAxis = svg.selectAll("g.x-axis").node()
        ? svg.selectAll("g.x-axis")
        : svg.append("g").attr("class", "x-axis")

      xAxis
        .attr("transform", `translate(${0},${height - paddings.bottom})`)
        .transition()
        .duration(1000)
        .call(xAxisCall)

      const yAxis = svg.selectAll("g.y-axis").node()
        ? svg.selectAll("g.y-axis")
        : svg.append("g").attr("class", "y-axis")

      yAxis
        .attr("transform", `translate(${paddings.left},${0})`)
        .transition()
        .duration(1000)
        .call(yAxisCall)

      svg
        .selectAll("g.bars")
        .data(data)
        .join(getBarGroupEnterFunction, getBarGroupUpdateFunction)
    }
  }, [scaleX, scaleY])

  return <svg className="bar-chart" id={id} width={width} height={height}></svg>
}

export default BarChart
