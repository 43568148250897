import React, { useState } from "react"
import { FormControl, Spinner, InputGroup } from "react-bootstrap"
import { assets } from "../../../../utils"
import AccessChart from "./access-chart/access-chart"
import "./analytics.scss"
import DeliveryDurationBarChart from "./delivery-duration"
import DeliveryFrequencyBarChart from "./delivery-frequency"
import DeliveryTimesBarChart from "./delivery-times"
import LiftPeakTimesLine from "./lift-peak-times-line"
import PeakTime from "./peak-times/peak-times"
import TopDeliveries from "./top-deliveries/top-deliveries"
import TopTenServiceProviders from "./top-sp-table/top-sp-table"

function Analytics(props) {
  const [query, setQuery] = useState("")

  return (
    <div className="analytics-screen">
      <h2 className="section-heading">Analytics {"&"} Reports</h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>

        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <div className="row g-3">
        <div className="col-md-6 col-xs-12 charts-container ">
          <div className="white-bg home-screen-chart p-3">
            <AccessChart />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3 ">
            <TopTenServiceProviders />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3">
            <LiftPeakTimesLine />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3">
            <PeakTime />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3">
            <DeliveryTimesBarChart />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3">
            <DeliveryDurationBarChart />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3">
            <TopDeliveries />
          </div>
        </div>
        <div className="col-md-6 col-xs-12 charts-container">
          <div className="white-bg home-screen-chart p-3">
            <DeliveryFrequencyBarChart />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
