import React from "react"
import BackArrowNoTail from "../../atoms/back-arrow-no-tail"

import "./settings.scss"

function Settings() {
  const links = [
    { name: "Privacy Policy" },
    { name: "Terms & Conditions" },
    { name: "Support/Send Feedback" },
    { name: "Delete Account" }
  ]

  return (
    <>
      <div className="d-flex">
        <BackArrowNoTail />
        <h2 className="section-heading my-5">Settings</h2>
      </div>
      <div className="white-bg p-3 settings">
        {links.map(link => (
          <p key={link.name} className="p-3 my-0 link-border">
            <a>{link.name}</a>
          </p>
        ))}
      </div>
    </>
  )
}

export default Settings
