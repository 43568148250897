import React from "react"
import { DateDropdown, Dropdown } from "../../../../../atoms"
import ColorIndex from "../../../../../atoms/color-index"
import Doughnut from "../../../../../charts/doughnut"

const ServiceProviderDeliveryCompetetion = () => {
  const data = [
    { percentage: 50, color: "#F2994A", name: "self" },
    { percentage: 50, color: "#219653", name: "others" }
  ]
  return (
    <>
      <div className="d-flex align-items-center p-4">
        <div className="chart-description w-50">
          <h3>No of Deliveries</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi
            suscipit mollitia officia corrupti.
          </p>
        </div>
        <DateDropdown className="ms-auto" />
      </div>
      <div className="doughnut-container text-center">
        <Doughnut
          data={data}
          id={"deliveryTimeSP"}
          outerRadius={160}
          thickness={80}
        />
      </div>
      <ColorIndex className={"mt-2 py-5"} data={data} />
    </>
  )
}

export default ServiceProviderDeliveryCompetetion
