import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"

const DatePickerComp = ({ className, onChange }) => {
  const [date, setSelectedDate] = useState("")

  useEffect(() => {
    onChange?.(new Date(date))
  }, [date])

  return (
    <div className={`dv-datepicker position-relative ${className}`}>
      <Dropdown className="position-absolute datepicker-dropdown">
        <Dropdown.Toggle variant="outline-dark">
          {date || "Select Date"}
        </Dropdown.Toggle>
      </Dropdown>
      <input
        type="date"
        onChange={e => setSelectedDate(e.target.value)}
        className="datepicker-input"
        value={date}
      />
    </div>
  )
}

export default DatePickerComp
