import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DaarnaButton } from "../../atoms"
import CenteredContainer from "../../containers/centered-container/centered-container"
import {
  assets,
  getUserTypeStringFromEnum,
  LocalStorage,
  setUserEnum,
  UserEnum,
  UserTypes
} from "../../utils"

import "./select-user.scss"
import UserSelectionCard from "./user-select-card"

const SelectUser = () => {
  const [selectedUserType, setselectedUserType] = useState(UserTypes.owner)
  const nav = useNavigate()

  const userTypes = [
    {
      title: UserTypes.serviceProvider,
      logo: assets.selectServiceProviderLogo
    },
    {
      title: UserTypes.owner,
      logo: assets.selectOwnerLogo
    }
  ]

  return (
    <div>
      <CenteredContainer containerClass="white-bg p-4 mt-5">
        <h5 className="py-3">Select a user</h5>
        <div className="d-flex justify-content-between align-items-center flex-wrap w-100">
          {userTypes.map(({ title, logo }) => (
            <div
              style={{ cursor: "pointer" }}
              key={title}
              className="d-flex justify-content-center flex-column align-items-center py-3 mx-2"
              onClick={() => {
                setselectedUserType(title)
              }}
            >
              <UserSelectionCard
                className={selectedUserType === title ? "selected" : ""}
                title={getUserTypeStringFromEnum(title)}
                logo={logo}
              />
            </div>
          ))}
        </div>
        <DaarnaButton
          text="CONTINUE"
          onClick={() => {
            setUserEnum(selectedUserType)
            LocalStorage.write("user-type", selectedUserType)
            nav("/login")
          }}
        />
      </CenteredContainer>
    </div>
  )
}

export default SelectUser
