import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import Analytics from "./pages/dashboard/dashboard-children/analytics/analytics"
import DashboardContainer from "./pages/dashboard/dashboard"
import { DashboardHome } from "./pages/dashboard/dashboard-children/home"
import Login from "./pages/login"
import SelectUser from "./pages/select-user/select-user"
import { SplashScreen } from "./pages/splash-screen"
import OrderReview from "./pages/dashboard/dashboard-children/order-review-screen/order-review"
import ViewUser from "./pages/dashboard/dashboard-children/view-user/view-user"
import Settings from "./pages/settings"
import CarrierFeedbackListing from "./pages/dashboard/dashboard-children/carrier-feedback"
import AcceptAccess from "./pages/dashboard/dashboard-children/accept-access/accept-access"
import PrivateRoute from "./containers/private-route"

const LoggedOutRoutes = [SplashScreen, Login, SelectUser]

const AppRoutes = () => {
  const element = useRoutes([
    { path: "", element: <SplashScreen />, index: true },
    {
      path: "select-user-screen",
      element: <SelectUser />
    },
    {
      path: "login",
      element: <Login />
    },
    {
      path: "dashboard",
      element: <PrivateRoute element={<DashboardContainer />} />,
      children: [
        { element: <DashboardHome />, path: "home", index: true },
        { element: <Analytics />, path: "analytics" },
        { element: <OrderReview />, path: "order-review" },
        { element: <ViewUser />, path: "user/:id" },
        { element: <Settings />, path: "settings" },
        { element: <CarrierFeedbackListing />, path: "carrier-feedback" },
        { element: <AcceptAccess />, path: "accept-access" },
        { element: <>In Progress</>, path: "orders" },
        { element: <>In Progress</>, path: "carrier-performance" },
        { element: <>In Progress</>, path: "services" },
        { element: <>In Progress</>, path: "profile" },
        {
          path: "*",
          element: <Navigate to="/dashboard/home" replace />
        }
      ]
    }
  ])

  // const test = <SplashScreen />

  // useEffect(() => {
  //   console.log(LoggedOutRoutes.includes(element?.props.children.type))
  // }, [element])

  return element
}

export default AppRoutes
