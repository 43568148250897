import React, { useState } from "react"
import { FormControl, InputGroup } from "react-bootstrap"
import { assets, getUserDetails } from "../utils"
import "./molecules-styles/header.scss"

const DashboardHeader = () => {
  return (
    <div className="dv-header d-flex align-items-center w-100">
      <InputGroup className="ms-5 my-3 dashboard-header-search-box">
        <InputGroup.Text className="dv-input-prefix ">
          <img src={assets.searchIcon} alt="search" />
        </InputGroup.Text>
        <FormControl
          placeholder="Search"
          className="dashboard-header-search"
        ></FormControl>
      </InputGroup>

      <div className="d-flex header-profile-details align-items-center ms-auto me-3">
        <span className="notification-icon me-3">
          <img src={assets.bellIcon} alt="" />
        </span>
        <span className="header-avatar-container">
          <img src={assets.avatar} alt="avatar" />
        </span>
        <div className="header-profile-info d-flex flex-column align-items-start justify-content-center ms-3">
          <span className="profile-name">{getUserDetails().name}</span>
          <span className="profile-role">{getUserDetails().email}</span>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
