import React from "react"
import { DateDropdown, Dropdown } from "../../../../../atoms"
import ColorIndexPallete from "../../../../../atoms/color-index"
import Doughnut from "../../../../../charts/doughnut"

const TopDeliveries = () => {
  const data = [
    { percentage: 33.33, color: "#F2994A", name: "Macdonald" },
    { percentage: 33.33, color: "#FEDEBC", name: "KFC" },
    { percentage: 33.33, color: "#219653", name: "Laundry" }
  ]

  return (
    <>
      <div className="d-flex align-items-center ">
        <div className="chart-description w-50">
          <h3>No of Deliveries</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi
            suscipit mollitia officia corrupti.
          </p>
        </div>
      </div>
      <div className="doughnut-container text-center my-5">
        <Doughnut
          data={data}
          id={"deliveryTimeSP"}
          outerRadius={160}
          thickness={80}
        />
      </div>
      <ColorIndexPallete className={"mt-2 py-5"} data={data} />
    </>
  )
}

export default TopDeliveries
