import React, { useState } from "react"
import { DateDropdown } from "../../../../../atoms"
import ColorIndexPallete from "../../../../../atoms/color-index"
import BarChart from "../../../../../charts/bar-chart"
import { data } from "../../../../../charts/sample-data/bar-chart"

function SP_BarChart() {
  const [usedData, setUsedData] = useState(
    data
      ?.map((e, i) => ({
        ...e,
        bars: [e.bars[0], { ...e.bars[0], height: e.bars[0].height / (i + 1) }]
      }))
      .map(e => ({
        ...e,
        bars: e.bars
          .slice(0, 2)
          .map((e, i) => ({ ...e, color: i % 2 == 0 ? "#F2994A" : "#219653" }))
      }))
  )

  return (
    <>
      <div className="d-flex align-items-center p-4">
        <div className="chart-description w-50">
          <h3>Delivery Period</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi
            suscipit mollitia officia corrupti.
          </p>
        </div>
        <DateDropdown className="ms-auto" />
      </div>
      <div className="text-center position-relative">
        <BarChart data={usedData} id="sp-bar-chart" />
      </div>
      <ColorIndexPallete
        className={"mt-2 py-5"}
        data={[
          { percentage: 33.33, color: "#F2994A", name: "Visitors" },
          { percentage: 33.33, color: "#219653", name: "Service Providers" }
        ]}
      />
    </>
  )
}

export default SP_BarChart
