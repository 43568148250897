import React, { useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import "./atoms-styles/dropdown.scss"

const DV_Dropdown = ({
  className = "",
  menu = [],
  labelKey = "name",
  placeholder = "",
  onChange = () => {},
  selectedOption = null,
  showNullOption = false,
  disabled = false,
  nullOptionName = ""
}) => {
  const [selected, setSelected] = useState(selectedOption)

  useEffect(() => {
    setSelected(selectedOption)
  }, [selectedOption])

  return (
    <Dropdown onSelect={ind => onChange(menu[ind])} className={className}>
      <Dropdown.Toggle
        className="w-100 drop-down-btn d-flex align-items-center"
        variant={"outline-dark"}
        disabled={disabled}
      >
        {selected?.[labelKey] || placeholder || "Select"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {showNullOption && (
          <Dropdown.Item eventKey={"default"}>
            <span className="dropdown-null-option">
              {nullOptionName || placeholder || "Select"}
            </span>
          </Dropdown.Item>
        )}
        {menu.map((el, i) => (
          <Dropdown.Item eventKey={i} key={`${el?.[labelKey]} ${i}`}>
            {el?.[labelKey]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DV_Dropdown
