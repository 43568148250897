export const data = [
  {
    bandName: "AD",
    bars: [{ height: 173, color: "hsl(228, 70%, 50%)" }]
  },
  {
    bandName: "AE",
    bars: [{ height: 134, color: "hsl(343, 70%, 50%)" }]
  },
  {
    bandName: "AF",
    bars: [
      { height: 199, color: "hsl(359, 70%, 50%)" },
      { height: 199, color: "hsl(359, 70%, 50%)" },
      { height: 199, color: "hsl(359, 70%, 50%)" },
      { height: 124, color: "hsl(359, 70%, 50%)" }
    ]
  },
  {
    bandName: "AG",
    bars: [{ height: 70, color: "hsl(60, 70%, 50%)" }]
  },
  {
    bandName: "AI",
    bars: [{ height: 149, color: "hsl(88, 70%, 50%)" }]
  },
  {
    bandName: "AL",
    bars: [{ height: 119, color: "hsl(112, 70%, 50%)" }]
  },
  {
    bandName: "AM",
    bars: [{ height: 127, color: "hsl(276, 70%, 50%)" }]
  }
]
