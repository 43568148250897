import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import CenteredContainer from "../../containers/centered-container/centered-container"
import GenericForm from "../../containers/form-composer/generic-form"
import {
  useLoginMutation,
  useSendOtpMutation
} from "../../queries-and-mutations/mutation"
import { assets, getUserEnum, LocalStorage, validation } from "../../utils"
import "./login.scss"

const Login = () => {
  const [otpSent, setOtpSent] = useState(false)
  const [submitDisabled, setdisabled] = useState(false)
  const sendOtpMutation = useSendOtpMutation()
  const loginMutation = useLoginMutation()
  const [submitText, setSubmitText] = useState(otpSent ? "Verify" : "Send OTP")
  const formRef = useRef()
  const nav = useNavigate()
  const formFields = [
    {
      type: "field",
      name: "email_or_number",
      prefix: (
        <div className="ps-2">
          <img src={assets.profileIcon} />
        </div>
      ),
      placeholder: "Email/Phone Number",
      rules: {
        required: "Email required!",
        validate: { ...validation.username }
      }
    },
    {
      type: "field",
      name: "otp",
      prefix: (
        <div className="ps-2">
          <img src={assets.lockIcon} />
        </div>
      ),
      placeholder: "OTP",
      rules: {
        validate: {
          ...validation.number,
          isrequired: val =>
            !otpSent || val.length >= 4 || "OTP required to proceed !"
        }
      },
      className: otpSent ? "" : "d-none"
    }
  ]

  const onSubmit = ({ email_or_number, otp }) => {
    const user_type = getUserEnum()
    if (!otpSent) {
      setOtpSent(true)
      setdisabled(true)
      setSubmitText("Sending...")
      sendOtpMutation.mutate(
        { email_or_number, user_type },
        {
          onSuccess: res => {
            setOtpSent(res.data.status)
            setSubmitText("Verify OTP")
            setdisabled(false)
          },
          onError: err => {
            setOtpSent(false)
            setdisabled(false)
            setSubmitText("Send OTP")
          }
        }
      )
      return
    }
    setSubmitText("Verifying...")
    setdisabled(true)
    loginMutation.mutate(
      { email_or_number, user_type, otp },
      {
        onSuccess: res => {
          setdisabled(false)
          const { token, user } = res.data.data
          LocalStorage.write("token", token)
          LocalStorage.write("user", user)
          nav("/dashboard")
        },
        onError: err => {
          setOtpSent(false)
          setSubmitText("Send OTP")
          formRef.current.reset()
        }
      }
    )
  }

  return (
    <div className="d-flex vh-100">
      <div
        style={{ backgroundImage: `url(${assets.loginBackgroundImage})` }}
        className="login-bg d-none d-md-block h-100 w-50 position-relative"
      >
        <div className="backdrop position-absolute h-100 w-100"></div>
        <img
          className="p-4 position-relative"
          src={assets.logoWhite}
          alt="logo"
        />
        <div className="spacer-parent d-flex flex-column">
          <div className="spacer"></div>
          <p className="login-welcome-text position-relative mx-auto text-center">
            Become a certified vendor on DV.
          </p>
        </div>
      </div>
      <div className="h-100 w-100 p-4 p-sm-0 w-sm-50 white-bg py-5 login-form-container">
        <CenteredContainer containerClass="login-form mt-2 mt-sm-5">
          <h4>Login To DV</h4>
          <p className="welcome-text-form">
            Welcome back! login with your data that you entered during
            registration
          </p>
          <GenericForm
            submitButtonText={submitText}
            className="mt-5"
            formFields={formFields}
            submitButtonClass="mt-5"
            onSubmit={onSubmit}
            submitDisabled={submitDisabled}
            ref={formRef}
            logging={false}
          />
        </CenteredContainer>
        <p className="mt-5 pt-3 text-center login-terms">
          <a>{"Terms & Privacy Policy"}</a>
        </p>
      </div>
    </div>
  )
}

export default Login
