import React from "react"
import { LineChart } from "../../../../../charts/line-chart"
import LineChartS from "../../../../../charts/line-chart-straight"

function LiftPeakTimesLine(props) {
  return (
    <>
      <div className="chart-description w-50 mb-5">
        <h3>Lift Peak Time</h3>
        <p>Lorem ipsum dolor sit amet .</p>
      </div>
      <div style={{ width: 550, height: 342 }}>
        <LineChartS minY={0} maxY={60} id={"line"} />
      </div>
    </>
  )
}

export default LiftPeakTimesLine
