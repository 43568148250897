import React, { useEffect, useImperativeHandle } from "react"
import { useForm, Controller } from "react-hook-form"
import { Form, FormGroup, FormControl, InputGroup } from "react-bootstrap"
import Button from "../../atoms/button"
import "./generic-form.scss"
import { Dropdown } from "../../atoms"

const GenericForm = React.forwardRef(
  (
    {
      formFields,
      submitButtonText,
      onSubmit,
      className = "",
      submitButtonClass = "",
      submitDisabled = false,
      logging = true,
      defaultValues
    },
    ref
  ) => {
    const forms = formFields

    const {
      control,
      watch,
      setError,
      formState: { errors },
      trigger,
      getValues,
      handleSubmit,
      reset,
      setValue
    } = useForm({ defaultValues })

    const formValues = watch()

    useEffect(() => {
      if (logging) {
        console.log(formValues, "values")
        console.log(errors, "errors")
      }
    }, [formValues])

    useImperativeHandle(ref, () => ({
      watch,
      getValues,
      trigger,
      reset,
      setValue
    }))

    useEffect(() => {
      trigger()
    }, [formFields])

    const getRender = formField => {
      let render
      switch (formField.compType) {
        case "field":
          render = render = ({
            field,
            fieldState: { invalid, isDirty, error, isTouched }
          }) => (
            <FormGroup className={`${formField?.className || ""}`}>
              <Form.Label className="">{formField.label}</Form.Label>
              <InputGroup>
                {formField?.prefix && (
                  <InputGroup.Text className="dv-input-prefix">
                    {formField?.prefix}
                  </InputGroup.Text>
                )}
                <Form.Control
                  className={`${invalid && isDirty ? "is-invalid" : ""} ${
                    formField?.prefix ? "input-with-prefix" : ""
                  } dv-form-input`}
                  {...field}
                  onChange={val => {
                    field.onChange(val)
                    formField?.onChange?.(val)
                    trigger(formField.name)
                  }}
                  type={formField.type || "text"}
                  placeholder={formField?.placeholder || ""}
                  disabled={formField?.disabled || ""}
                />
              </InputGroup>
              {error && isTouched && (
                <Form.Control.Feedback className="d-block" type="invalid">
                  {error?.message}
                </Form.Control.Feedback>
              )}
            </FormGroup>
          )
          break

        case "dropdown":
          render = ({ field, fieldState: { invalid, isDirty, error } }) => (
            <FormGroup
              className={`${
                formField?.className || ""
              } dv-default-input-container position-relative`}
            >
              <Form.Label className="">{formField.label}</Form.Label>
              <Dropdown
                onChange={val => {
                  field.onChange(val)
                  formField?.onChange?.(val)
                  trigger(formField.name)
                }}
                className="w-100"
                menu={formField.menu || []}
                selectedOption={field.value}
                labelKey={formField?.labelKey}
                disabled={formField?.disabled || ""}
              />
            </FormGroup>
          )
          break

        case "custom":
          render = ({ field, fieldState: { invalid, isDirty, error } }) => (
            <formField.component
              {...{ field, invalid, isDirty, error }}
              trigger={() => trigger(formField.name)}
            />
          )
          break

        default:
          render = ({
            field,
            fieldState: { invalid, isDirty, error, isTouched }
          }) => (
            <FormGroup
              className={`${
                formField?.className || ""
              } dv-default-input-container position-relative`}
            >
              <Form.Label className="">{formField.label}</Form.Label>
              <InputGroup>
                {formField?.prefix && (
                  <InputGroup.Text className="dv-input-prefix">
                    {formField?.prefix}
                  </InputGroup.Text>
                )}
                <Form.Control
                  className={`${invalid && isDirty ? "is-invalid" : ""} ${
                    formField?.prefix ? "input-with-prefix" : ""
                  } dv-form-input`}
                  {...field}
                  onChange={val => {
                    field.onChange(val)
                    formField?.onChange?.(val)
                    trigger(formField.name)
                  }}
                  type={formField.type || "text"}
                  placeholder={formField?.placeholder || ""}
                  disabled={formField?.disabled || ""}
                />
              </InputGroup>
              {error && isTouched && (
                <Form.Control.Feedback
                  tooltip
                  className="d-block"
                  type="invalid"
                >
                  {error?.message}
                </Form.Control.Feedback>
              )}
            </FormGroup>
          )
          break
      }
      return render
    }

    return (
      <>
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
          {forms.map(e => (
            <Controller
              key={e.name}
              defaultValue={e.defaultValue || ""}
              disabled={e.disabled || ""}
              name={e.name}
              control={control}
              rules={e.rules}
              render={getRender(e)}
            />
          ))}
          <Button
            className={submitButtonClass}
            text={submitButtonText}
            disabled={Object.keys(errors).length || submitDisabled}
            onClick={() => onSubmit(formValues, setError)}
          />
        </form>
      </>
    )
  }
)

export default GenericForm
