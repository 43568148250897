import React, { useEffect } from "react"
import { FormControl, InputGroup, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { svgIcons } from "../../../../assets/svgIcons"
import { Dropdown } from "../../../../atoms"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import { Table } from "../../../../molecules"
import {
  useGetCarriers,
  useGetUsers
} from "../../../../queries-and-mutations/queries"
import {
  assets,
  getUserEnum,
  getUserTypeStringFromEnum,
  UserTypes
} from "../../../../utils"
import AddCarrier from "./add-carrier"
import { EditUserScreen } from "./add-user"
import "./home.scss"
import SP_BarChart from "./sp-charts/sp-bar-chart"
import ServiceProviderDeliveryCompetetion from "./sp-charts/delivery-competetion-doughnut"
import WaitingTimeChart from "./owner-charts/waiting-time-chart"
import AccessAreaChart from "./owner-charts/access-area-chart"

function Home() {
  const nav = useNavigate()
  const userEnum = getUserEnum()
  const isServiceProvider = userEnum == UserTypes.serviceProvider
  const isOwner = userEnum == UserTypes.owner
  const [page, setPage] = React.useState(1)
  const [query, setQuery] = React.useState("")
  const [allowFetchCarrier, setAllowFetchCarrier] = React.useState(false)
  const [allowFetchUsers, setAllowFetchUsers] = React.useState(false)
  const [fetchTimeout, setFetchTimeout] = React.useState()
  const [searchTerm, setSearchTerm] = React.useState("")
  const [textLoading, setTextLoading] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)

  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()

  const openAddUserPopup = (editMode = false, user = {}) => {
    setModalContentClass("p-5")
    setModalComponent(() => (
      <EditUserScreen isEditMode={editMode} user={user} />
    ))
    setModalVisibility(true)
  }

  const openAddCarrierPopup = (editMode = false, user = {}) => {
    setModalContentClass("p-5")
    setModalComponent(() => <AddCarrier isEditMode={editMode} user={user} />)
    setModalVisibility(true)
  }

  const size = 10
  const { data: carrierResponse, isFetching: carrierFetching } = useGetCarriers(
    page,
    searchTerm,
    size,
    {
      enabled: isServiceProvider && allowFetchCarrier
    }
  )

  const getUserTypeParams = () =>
    selectedUser?.value ? { user_type: selectedUser.value } : {}

  const { data: usersResponse, isFetching: usersFetching } = useGetUsers(
    page,
    searchTerm,
    size,
    getUserTypeParams(),
    {
      enabled: isOwner && allowFetchUsers
    }
  )

  const fetchForQuery = () => {
    if (!textLoading) setTextLoading(true)
    if (fetchTimeout) clearTimeout(fetchTimeout)
    setFetchTimeout(
      setTimeout(() => {
        setSearchTerm(query)
        setTextLoading(false)
      }, 1000)
    )
  }

  useEffect(() => {
    if (query.length == "") {
      if (isServiceProvider) setAllowFetchCarrier(true)
      if (isOwner) setAllowFetchUsers(true)
    }
    fetchForQuery()
  }, [query])

  const getCarrierProfileCell = cell => (
    <div className="d-flex table-profile-cell align-items-center ">
      <span className="header-avatar-container">
        <img src={assets.avatar} alt="avatar" />
      </span>
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="profile-name">{`${cell.first_name} ${cell.last_name}`}</span>
        <span className="profile-email">{cell.email}</span>
      </div>
    </div>
  )

  const getActionCell = cell => (
    <div className="d-flex mx-auto table-actions-container justify-content-between p-1 px-3">
      <span className="action-cell">
        <img src={assets.maskIcon} alt="mask" />
      </span>
      <span
        onClick={() =>
          isServiceProvider
            ? openAddCarrierPopup(true, cell)
            : openAddUserPopup(true, cell)
        }
        className="action-cell"
      >
        <svgIcons.EditIcon />
      </span>
      <span className="action-cell">
        <svgIcons.DeleteIcon />
      </span>
    </div>
  )

  const getCategoryHeader = () => (
    <Dropdown
      className="table-header-dropdown border-0 no-hover-highlight d-flex justify-content-center"
      menu={[
        ...Object.keys(UserTypes).map(user => ({
          name: getUserTypeStringFromEnum(UserTypes[user]),
          value: UserTypes[user]
        }))
      ]}
      onChange={e => {
        setSelectedUser(e)
        setPage(1)
      }}
      selectedOption={selectedUser}
      placeholder="Category"
      showNullOption={true}
    />
  )

  const columns = React.useMemo(() => {
    if (isServiceProvider)
      return [
        {
          Header: "Carrier Full Name",
          styles: { textAlign: "left", paddingLeft: "42px" },
          accessor: getCarrierProfileCell
        },
        {
          Header: "ID Number",
          accessor: "id_number"
        },
        {
          Header: "Mobile Number",
          accessor: "phone"
        },
        {
          Header: "Action",
          accessor: getActionCell
        }
      ]
    if (isOwner)
      return [
        {
          Header: "Name",
          accessor: cell => (
            <span
              // onClick={() => nav(`/dashboard/user/${cell?.user_profile?.id}`)}
              onClick={() => nav(`/dashboard/user/${cell.id}`)}
              style={{ cursor: "pointer" }}
            >
              {cell["name"]}
            </span>
          )
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
          Header: "Phone",
          accessor: "phone"
        },
        {
          id: "category",
          Header: getCategoryHeader,
          accessor: cell => getUserTypeStringFromEnum(cell.user_type),
          styles: {
            minWidth: "230px",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            minHeight: "65px"
          }
        },
        {
          Header: "Action",
          accessor: getActionCell
        }
      ]
  }, [selectedUser])

  const carrierResponseData = carrierResponse?.data?.data || {}
  const { results: carrierData } = carrierResponseData

  const usersResponseData = usersResponse?.data || {}
  const { results: usersData } = usersResponseData

  const { count, previous, next } =
    (isOwner ? usersResponseData : carrierResponseData) || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const isLoading = carrierFetching || usersFetching

  return (
    <div className="home-screen">
      <h2 className="section-heading">Home</h2>
      <div className="d-flex home-chart-holder">
        <div className="home-screen-chart d-flex flex-column w-50 white-bg">
          {isServiceProvider ? <SP_BarChart /> : <WaitingTimeChart />}
        </div>
        <div className="home-screen-chart d-flex flex-column w-50 white-bg">
          {isServiceProvider ? (
            <ServiceProviderDeliveryCompetetion />
          ) : (
            <AccessAreaChart />
          )}
        </div>
      </div>

      <h2 className="section-heading">
        {isServiceProvider ? "Carriers" : "Users"}
      </h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={isServiceProvider ? "Search Carriers" : "Search Users"}
            className="dashboard-header-search w-50"
          />
          {textLoading && (
            <InputGroup.Text style={{ borderLeft: 0 }} className="white-bg">
              <Spinner variant="warning" animation="grow" size="sm" />
            </InputGroup.Text>
          )}
        </InputGroup>

        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        {/* <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <img src={assets.dropdownArrow} alt="" />
        </button> */}
        {isOwner ? (
          <button
            onClick={() => openAddUserPopup()}
            className="page-btn mx-2 px-2"
          >
            Add User
          </button>
        ) : (
          <button
            onClick={() => openAddCarrierPopup()}
            className="page-btn mx-2 px-2"
          >
            Add Carrier
          </button>
        )}
      </div>
      <Table
        data={(isServiceProvider ? carrierData : usersData) || []}
        columns={columns}
        count={count}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageSize={size}
        isLoading={isLoading}
      />
    </div>
  )
}

export default Home
