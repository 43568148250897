import { ResponsiveLine } from "@nivo/line"

const sample = [
  {
    id: "Series 1",
    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => ({
      x: n * 2.5,
      y: n != 0 ? Math.floor(Math.random() * 60) : 0
    }))
  }
]

export const LineChart = ({ data = sample }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 25, right: 25, bottom: 25, left: 25 }}
    xScale={{ type: "point", width: 342 }}
    yScale={{
      type: "linear",
      min: "auto",
      max: 60,
      stacked: true,
      reverse: false,
      height: 550
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "transportation",
      legendOffset: 36,
      legendPosition: "middle"
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "count",
      legendOffset: -40,
      legendPosition: "middle"
    }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    colors={"#5FC98F"}
    useMesh={true}
    legends={[
      {
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)
