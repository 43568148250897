import React, { useLayoutEffect, useState, useEffect } from "react"
import * as d3 from "d3"

const sample = [
  {
    id: "Series 1",
    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => ({
      x: n * 2.5,
      y: n != 0 ? Math.floor(Math.random() * 60) : 0
    })),
    color: "#5FC98F"
  }
]

const LineChartS = ({
  id,
  width = 550,
  height = 324,
  paddings = { top: 25, left: 25, right: 50, bottom: 50 },
  data = sample,
  maxY,
  minY,
  minX,
  maxX,
  xGrid = 8,
  yGrid = 8
}) => {
  const noOFTicksInX =
    xGrid || d3.max(data.map(d => d3.max(d.data.map(e => e.x))))
  const noOFTicksInY = yGrid || 10
  const createScales = () => {
    const _xScale = d3
      .scaleLinear()
      .domain([
        minX || d3.min(data.map(d => d3.min(d.data.map(e => e.x)))),
        maxX || d3.max(data.map(d => d3.max(d.data.map(e => e.x))))
      ])
      .range([paddings.left, width - paddings.right])

    const _yScale = d3
      .scaleLinear()
      .domain([
        minY || d3.min(data.map(d => d3.min(d.data.map(e => e.y)))),
        maxY || d3.max(data.map(d => d3.max(d.data.map(e => e.y))))
      ])
      .range([height - paddings.bottom, paddings.top])

    return [_xScale, _yScale]
  }

  const innerWidth = width - paddings.left - paddings.right
  const innerHeight = height - paddings.top - paddings.bottom
  const [scaleX, setScalX] = useState(undefined)
  const [scaleY, setScalY] = useState(undefined)

  const createAxes = () => {
    if (scaleX && scaleY)
      return [
        d3.axisBottom(scaleX).tickSize(4),
        d3.axisLeft(scaleY).tickSize(4)
      ]
    return []
  }

  const makeGridLines = (svg, orientation = "x") => {
    const isX = orientation == "x"
    const tickNumber = isX ? noOFTicksInY : noOFTicksInX
    svg.selectAll(`g.${orientation}-grid-line`).remove()
    const a = new Array(tickNumber).fill(e => ({}))
    a.forEach((e, i) => {
      svg
        .append("g")
        .attr("class", `${orientation}-grid-line`)
        .attr(
          "transform",
          `translate(${
            (isX ? 0 : innerWidth / tickNumber) * (i + 1) + paddings.left
          },${((isX ? innerHeight : 0) / tickNumber) * (i + 1) + paddings.top})`
        )
        .append("line")
        .attr(isX ? "x1" : "y1", 0)
        .attr(isX ? "x2" : "y2", isX ? innerWidth : innerHeight)
        .attr(isX ? "y" : "x", 0)
        .attr("stroke-width", "1px")
        .attr("stroke", "black")
        .attr("opacity", "0.1")
    })
  }

  const addFocus = svg => {
    const focus = svg.select("g.focus").node()
      ? svg.select("g.focus")
      : svg.append("g").attr("class", "focus")

    focus
      .append("circle")
      .attr("r", 5)
      .attr("stroke", data[0]?.color)
      .attr("stroke-width", "3px")
      .attr("fill", "transparent")

    focus
      .append("line")
      .attr("class", "x-hover-line")
      .attr("x1", -width)
      .attr("x2", width)
      .attr("stroke-dasharray", "3 1")
      .attr("stroke", "black")
      .attr("opacity", "0.1")

    focus
      .append("line")
      .attr("class", "y-hover-line")
      .attr("y1", height)
      .attr("y2", -height)
      .attr("x", 0)
      .attr("stroke-dasharray", "3 1")
      .attr("stroke", "black")
      .attr("opacity", "0.1")
      .attr("stroke-width", "1px")

    const text = focus.select("text").node()
      ? focus.select("text")
      : focus.append("text").attr("dy", 3).attr("dx", 3)
    text.attr("transform", `scale(0.75,0.75)`)

    return focus
  }

  useEffect(() => {
    const [x, y] = createScales()
    setScalX(() => x)
    setScalY(() => y)
  }, [data])

  useEffect(() => {
    const [xAxisCall, yAxisCall] = createAxes()
    if (xAxisCall && yAxisCall) {
      const svgContainer = d3.select(`svg#${id}`)
      const svg = svgContainer.select("g.container").node()
        ? svgContainer.select("g.container")
        : svgContainer.append("g").attr("class", "container")

      const xAxis = svg.selectAll("g.x-axis").node()
        ? svg.selectAll("g.x-axis")
        : svg.append("g").attr("class", "x-axis")

      xAxis
        .attr("transform", `translate(${0},${height - paddings.bottom})`)
        .transition()
        .duration(1000)
        .call(xAxisCall)

      const yAxis = svg.selectAll("g.y-axis").node()
        ? svg.selectAll("g.y-axis")
        : svg.append("g").attr("class", "y-axis")

      yAxis
        .attr("transform", `translate(${paddings.left},${0})`)
        .transition()
        .duration(1000)
        .call(yAxisCall)

      const line = d3
        .line()
        .x(d => scaleX(d.x))
        .y(d => scaleY(d.y))

      svg
        .selectAll("g.line")
        .data(data)
        .join(enter => enter.append("path").attr("d", d => line(d.data)))
        .attr("fill", "none")
        .attr("stroke-width", "2px")
        .attr("stroke", d => d.color)

      makeGridLines(svg, "x")
      makeGridLines(svg, "y")
      const focus = addFocus(svg)

      svg
        .append("rect")
        .attr("height", innerHeight)
        .attr("width", innerWidth)
        .attr("transform", `translate(${paddings.left},${paddings.top})`)
        .attr("opacity", "0")
        .on("mouseover", e => focus.style("display", null))
        .on("mouseout", e => focus.style("display", "none"))
        .on("mousemove", e => {
          const [x, y] = d3.pointer(e)
          const xValue = scaleX.invert(x)
          //   const i = d3.bisect(data[0]?.data?.map(e => e.x))
          const { left } = d3.bisector(e => e.x)
          const i = left(data[0].data, xValue)
          const d0 = data[0]?.data?.[i]
          const d1 = data[0]?.data?.[i - 1]
          const point =
            Math.abs(d0.x - xValue) > Math.abs(d1.x - xValue) ? d1 : d0
          const last = data[0].data.at(-1)
          const goToLast = scaleX(Math.abs(xValue - last.x)) < 60

          focus
            .transition()
            .duration(100)
            .attr(
              "transform",
              `translate(${goToLast ? scaleX(last.x) : scaleX(point.x)},${
                goToLast ? scaleY(last.y) : scaleY(point.y)
              })`
            )

          focus
            .select("text")
            .text(
              `x: ${goToLast ? last.x : point.x}, y: ${
                goToLast ? last.y : point.y
              }`
            )
        })
    }
  }, [scaleX, scaleY])

  return (
    <svg className={"doughnut"} id={id} width={width} height={height}></svg>
  )
}

export default LineChartS
