import React from "react"
import { data } from "../../../../../charts/sample-data/bar-chart"
import BarChart from "../../../../../charts/bar-chart"
import ColorIndexPallete from "../../../../../atoms/color-index"

const usedData = data
  ?.map((e, i) => ({
    ...e,
    bars: [e.bars[0], { ...e.bars[0], height: e.bars[0].height / (i + 1) }]
  }))
  .map(e => ({
    ...e,
    bars: e.bars
      .slice(0, 2)
      .map((e, i) => ({ ...e, color: i % 2 == 0 ? "#F2994A" : "#219653" }))
  }))

const Svg = () => (
  <svg
    width="21"
    height="27"
    viewBox="0 0 21 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="3" height="27" rx="1.5" fill="#E0E0E0" fillOpacity="0.6" />
    <rect
      x="18"
      width="3"
      height="27"
      rx="1.5"
      fill="#E0E0E0"
      fillOpacity="0.6"
    />
    <rect
      x="6"
      y="9"
      width="3"
      height="18"
      rx="1.5"
      fill="#E0E0E0"
      fillOpacity="0.6"
    />
    <rect
      x="12"
      y="15"
      width="3"
      height="12"
      rx="1.5"
      fill="#E0E0E0"
      fillOpacity="0.6"
    />
  </svg>
)

function AccessChart(props) {
  return (
    <>
      <div className="chart-description w-50">
        <h3>Access</h3>
        <p>Lorem ipsum dolor sit amet .</p>
      </div>
      <div className="d-flex w-50 my-5">
        <div className="d-flex flex-column">
          <div>
            <Svg /> <span className="number-text px-1">500k</span>
          </div>
          <span className="sub-text pt-1">Visitors</span>
        </div>
        <div className="d-flex flex-column ms-5">
          <div>
            <Svg /> <span className="number-text px-1">300k</span>
          </div>
          <span className="sub-text pt-1">Service Providers</span>
        </div>
      </div>
      <div className="text-center position-relative">
        <BarChart data={usedData} id="Access-chart" />
      </div>
      <ColorIndexPallete
        className={"mt-2 py-2"}
        data={[
          { percentage: 33.33, color: "#F2994A", name: "Visitors" },
          { percentage: 33.33, color: "#219653", name: "Service Providers" }
        ]}
      />
    </>
  )
}

export default AccessChart
