import React, { useState } from "react"
import BarChart from "../../../../../charts/bar-chart"
import { data } from "../../../../../charts/sample-data/bar-chart"

function DeliveryDurationBarChart(props) {
  const [usedData, setUsedData] = useState(
    data.map(e => ({ ...e, bars: [{ ...e.bars[0], color: "#F2994A" }] }))
  )

  return (
    <>
      <div className="d-flex align-items-center p-2">
        <div className="chart-description w-50">
          <h3>Waiting Time</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
      </div>
      <div className="text-center position-relative">
        <BarChart height={500} data={usedData} id="delivery-duration" />
      </div>
    </>
  )
}

export default DeliveryDurationBarChart
