import React from "react"

function ColorIndexPallete({ data = [], className }) {
  return (
    <>
      <div className={`d-flex align-items-center  ${className}`}>
        {data.map((el, index) => (
          <React.Fragment key={index}>
            <span
              style={{ background: el.color }}
              className=" indicator-bar"
            ></span>
            <span className="indicator-text ms-3">{el?.name}</span>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default ColorIndexPallete
