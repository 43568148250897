import React from "react"
import { useQuery } from "react-query"
import { getUserEnum, webApiHandler } from "../utils"

export const useGetCarriers = (page, q, size = 10, config = {}) => {
  return useQuery(
    ["carriers", page, size, q],
    () => webApiHandler.getCarriers(page, q, size),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetUsers = (page, q, size = 10, params, config = {}) => {
  return useQuery(
    ["users", page, size, q, params],
    () => webApiHandler.getUsers(page, q, size, params),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetCategories = (config = {}) => {
  return useQuery(["categories"], () => webApiHandler.getCategories(), config)
}

export const useGetSubCategories = (id, config = {}) => {
  return useQuery(
    ["subCategories", id],
    () => webApiHandler.getSubCategories(id),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetAllBuildings = config => {
  return useQuery(["buildings"], () => webApiHandler.getAllBuildings(), config)
}

export const useGetUser = ({ id }, config = {}) => {
  return useQuery(["user", id], () => webApiHandler.getUser(id), { ...config })
}

export const useGetFeedbackListings = (params = {}, config = {}) => {
  return useQuery(
    ["feedback", getUserEnum(), params],
    () => webApiHandler.getFeedbackListing(),
    { keepPreviousData: true, ...config }
  )
}
