import React from "react"
import { data } from "../../../../../charts/sample-data/bar-chart"
import BarChart from "../../../../../charts/bar-chart"
import ColorIndexPallete from "../../../../../atoms/color-index"

const usedData = data
  ?.map((e, i) => ({
    ...e,
    bars: [e.bars[0], { ...e.bars[0], height: e.bars[0].height / (i + 1) }]
  }))
  .map(e => ({
    ...e,
    bars: e.bars
      .slice(0, 2)
      .map((e, i) => ({ ...e, color: i % 2 == 0 ? "#F2994A" : "#219653" }))
  }))

function PeakTime(props) {
  return (
    <>
      <div className="chart-description w-50">
        <h3>Peak Times</h3>
        <p>Lorem ipsum dolor sit amet .</p>
      </div>
      <div className="text-center position-relative my-5">
        <BarChart data={usedData} id="peak-time-chart" />
      </div>
      <ColorIndexPallete
        className={"mt-2 py-5"}
        data={[
          { color: "#F2994A", name: "Visitors" },
          { color: "#219653", name: "Service Providers" }
        ]}
      />
    </>
  )
}

export default PeakTime
