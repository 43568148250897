import React from "react"
import { Table } from "../../../../../molecules"

function TopTenServiceProviders(props) {
  const [data, setData] = React.useState(() =>
    new Array(10).fill({
      name: "Kores Ltd",
      slug: "Korean",
      number: 200
    })
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Service Provider",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: "name"
      },
      {
        Header: "Service Name",
        accessor: "slug"
      },
      {
        Header: "No. of Deliveries",
        accessor: "number"
      }
    ],
    []
  )
  return (
    <>
      <div className="chart-description position-relative w-100 table-descriptor py-3 white-bg">
        <h3>Access</h3>
        <p>Lorem ipsum dolor sit amet .</p>
      </div>
      <Table
        columns={columns}
        fixedHead={true}
        data={data}
        hidePagination={true}
      />
      <div className="d-flex">
        <a
          onClick={() => setData(pr => pr.concat(pr))}
          className="view-more-btn ms-auto p-3"
        >
          View More
        </a>
      </div>
    </>
  )
}

export default TopTenServiceProviders
