import { post, get, patch } from "./http-methods"

import loginBackgroundImage from "../assets/images/login-background.png"
import selectServiceProviderLogo from "../assets/images/sp-logo.png"
import selectOwnerLogo from "../assets/images/sp-logo.png"
import logoWhite from "../assets/images/logo-white.png"
import profileIcon from "../assets/images/profile.svg"
import lockIcon from "../assets/images/lockIcon.svg"
import searchIcon from "../assets/images/search-icon.svg"
import bellIcon from "../assets/images/bell.svg"
import avatar from "../assets/images/avatar.svg"
import daarnaLogo from "../assets/images/daarna-logo.svg"
import dvTextIcon from "../assets/images/dv-text.svg"
import maskIcon from "../assets/images/action-mask-icon.svg"
import downloadIcon from "../assets/images/download.svg"
import dropdownArrow from "../assets/images/drop-down-arrow.svg"
import sortIcon from "../assets/images/sort.svg"
import backArrowNoTail from "../assets/images/back-arrow.svg"
import mockQRcode from "../assets/images/qr-sample.svg"
import callIcon from "../assets/images/call-icon.svg"
import mailIcon from "../assets/images/mail-icon.svg"
import logoutIcon from "../assets/images/logout-icon.svg"

export const assets = {
  loginBackgroundImage,
  selectServiceProviderLogo,
  selectOwnerLogo,
  logoWhite,
  profileIcon,
  lockIcon,
  searchIcon,
  bellIcon,
  avatar,
  daarnaLogo,
  dvTextIcon,
  maskIcon,
  downloadIcon,
  dropdownArrow,
  sortIcon,
  backArrowNoTail,
  mockQRcode,
  callIcon,
  mailIcon,
  logoutIcon
}

export const LocalStorage = {
  write: (key, val) => {
    if (val && typeof val === "object")
      localStorage.setItem(key, JSON.stringify(val))
    else localStorage.setItem(key, val)
  },
  read: key => {
    let str = localStorage.getItem(key)
    if (["{", "["].find(e => str?.charAt(0) == e) || str == "null") {
      return JSON.parse(str)
    }
    return str
  }
}

const regex = {
  internationalPhone: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^([a-zA-Z, .'-]){3,}$/i
}

export const UserTypes = {
  serviceProvider: 3,
  owner: 4,
  tenant: 1,
  carrier: 2
}

export const UserEnum = {
  TENANT: 1,
  CARRIER: 2,
  serviceProvider: 3,
  Owner: 4,
  ADMIN: 5
}

export const getUserTypeStringFromEnum = _enum => {
  switch (_enum) {
    case 1:
      return "Tenant"
    case 2:
      return "Carrier"
    case 3:
      return "Service Provider"
    case 4:
      return "Owner"
    case 5:
      return "Admin"
  }
}

export const validation = {
  //** do not name the method for matching against regex as pattern as it seems to create name conflict with useform library **
  username: {
    match: username =>
      regex.email.test(username) ||
      regex.internationalPhone.test(username) ||
      "Not a valid phone/email"
  },

  name: {
    match: name =>
      !name ||
      regex.name.test(name) ||
      (name.length < 3 ? "Too short" : "Invalid name")
  },

  nameMax30: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,30}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 30
        ? "Too long"
        : "Invalid name")
  },

  nameMax40: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,40}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 40
        ? "Too long"
        : "Invalid name")
  },

  email: {
    match: email => !email || regex.email.test(email) || "invalid email address"
  },

  number: {
    match: number => !number || /^\d+(\.\d+)?$/.test(number) || "Invalid number"
  },

  phone: {
    us: phoneNumber =>
      !phoneNumber ||
      (/^\d+$/.test(phoneNumber) && phoneNumber.length == 10) ||
      "invalid phone number"
  },

  dob: {
    notToday: dateInput => {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      return (
        !dateInput ||
        new Date(dateInput).getTime() < today.getTime() ||
        "Please enter your date of birth"
      )
    }
  },
  internationalPhone: {
    internationalPhone: val =>
      regex.internationalPhone.test(val) || "invalid phone"
  }
}

export const getUserEnum = () => parseInt(LocalStorage.read("user-enum"))
export const setUserEnum = _enum => LocalStorage.write("user-enum", _enum)
export const getUserDetails = () => LocalStorage.read("user")
export const resolveKeyPath = (obj, keyPath, { fallbackValue } = {}) => {
  return (
    keyPath.split(".").reduce((acc, key) => acc?.[key], obj) || fallbackValue
  )
}
export const placeValueOnKeyPath = (
  destinationObj = {},
  destinationKeyPath,
  sourceObj,
  sourceKeyPath
) => {
  const [keyToUse, ...rest] = destinationKeyPath.split(".")
  if (keyToUse)
    return {
      ...destinationObj,
      [keyToUse]: placeValueOnKeyPath(
        destinationObj?.[keyToUse],
        rest.join("."),
        sourceObj,
        sourceKeyPath
      )
    }
  return resolveKeyPath(sourceObj, sourceKeyPath)
}

export const extractValuesFromForm = (keyArr, vals) =>
  keyArr.reduce((acc, key) => {
    if (!Array.isArray(key)) return { ...acc, [key]: resolveKeyPath(vals, key) }
    return placeValueOnKeyPath(acc, key[0], vals, key[1])
  }, {})

export const translateSvg = (x, y) => ["transform", `translate(${x},${y})`]

export const webApiHandler = {
  sendOtp: payload => post("/home/api/v1/send-phone-otp/", payload),
  resendOtp: payload => post("/home/api/v1/login/resend-otp/", payload),
  verifyOtp: payload => post("/home/api/v1/confirm-phone-otp/", payload),
  getCarriers: (page, q, page_size = 10) =>
    get("/carriers/api/v1/get-carriers/", { params: { page, page_size, q } }),
  getUsers: (page, q, page_size = 10, params = {}) =>
    get("/user/api/v1/all-users/", {
      params: { page, page_size, q, ...params }
    }),
  getCategories: () => get("/home/api/v1/get-categories/"),
  getSubCategories: id => get(`/home/api/v1/get-sub-categories/${id}/`),
  createUser: payload => post("/home/api/v1/signup/", payload),
  getAllBuildings: () => get("/property/api/v1/building/"),
  editUser: (id, payload) => patch(`/user/api/v1/user-profile/${id}/`, payload),
  getUser: id => get(`/user/api/v1/get-user/${id}/`),
  getFeedbackListing: (params = {}) =>
    get(`/user/api/v1/user-profile-feedback/`, { params }),
  feedbackReply: (id, payload) =>
    patch(`/user/api/v1/user-profile-feedback/${id}/`, payload)
}
