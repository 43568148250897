import React from "react"
import { FormControl, InputGroup, Spinner } from "react-bootstrap"
import Button from "../../../../atoms/button"
import { Table } from "../../../../molecules"
import { assets } from "../../../../utils"

function OrderReview() {
  const [query, setQuery] = React.useState("")

  const columns = React.useMemo(
    () => [
      {
        Header: "Carrier Name",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: cell => "Amanda Jake"
      },
      {
        Header: "User Email",
        accessor: () => "Mandy@eg.com"
      },
      {
        Header: "Order Details",
        accessor: () => "Chicken Wings"
      },
      {
        Header: "Order Time",
        accessor: () => new Date().toUTCString()
      },
      {
        Header: "Delivery Time",
        accessor: () => new Date().toUTCString()
      },
      {
        Header: "Action",
        accessor: () => (
          <div>
            <Button className="h-32p" text="Review" />
          </div>
        )
      }
    ],
    []
  )

  return (
    <div>
      <h2 className="section-heading">{"Order Reviews"}</h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search Reviews"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>
        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <Table
        data={new Array(10).fill({})}
        columns={columns}
        // count={count}
        // page={page}
        // onNext={onNext}
        // onPrev={onPrev}
        // pageSize={size}
        // isLoading={isLoading}
      />
    </div>
  )
}

export default OrderReview
